import React, {useState, useCallback, useContext, useEffect} from 'react';
import styled from 'styled-components';
import useDimensions from '../hooks/use-dimension';
import {Table} from 'antd';
import {Context} from '../AppContext';
import {dateUtil} from '../Utils';
import {Row, Text, Label} from '../Widgets';
import Image from './Image';
const appConfig = require('../data.json');

const PAGINATION = {
  limit: 10,
  current: 1,
};

const UISTATE = {
  credits: 'credits',
  bonus: 'bonus',
};

function parseNote(record) {
  let result = '';
  if (record.order_display_id) {
    result = `訂單編號 ${record.order_display_id}`;
  }
  if (record && record.value) {
    if (record.value.feedback) {
      result += ` 回饋 ${record.value.feedback}`;
    }
    if (record.value.note) {
      result +=
        {
          bonus_gift: '撒紅利',
          dashboard: '後台操作',
        }[record.value.note] || record.value.note;
    }
  }
  return result;
}

const columns = {
  bonus: [
    {
      title: '日期',
      dataIndex: 'created',
      key: 'created',
      render: (created) => dateUtil.format(created),
      responsive: ['sm'],
    },
    {
      title: '原紅利金',
      key: '_bonus',
      render: (record) => record.value._bonus || '---',
      responsive: ['sm'],
    },
    {
      title: '差額',
      key: 'diff',
      render: (record) => {
        if (!record.value.diff) {
          return record.value.bonus - record.value._bonus;
        } else {
          return (
            `${record.value.diff || ''} ${
              record.value.feedback ? '+' + record.value.feedback : ''
            }`.trim() || '---'
          );
        }
      },
      responsive: ['sm'],
    },
    {
      title: '剩餘紅利金',
      key: 'bonus',
      render: (record) => record.value.bonus || '---',
      responsive: ['sm'],
    },
    {
      title: '備註',
      key: 'note',
      render: (record) => parseNote(record),
      responsive: ['sm'],
    },
    {
      title: '紀錄',
      key: 'id',
      className: 'rwd-mobile',
      render: (record) => {
        const diff = !record.value.diff
          ? record.value.bonus - record.value._bonus
          : `${record.value.diff || ''} ${
              record.value.feedback ? '+' + record.value.feedback : ''
            }`.trim() || '---';

        return (
          <div>
            <Row key="created">
              <Label flex="0 0 80px">日期</Label>
              <Text size="sm">{dateUtil.format(record.created)}</Text>
            </Row>
            <Row key="_bonus">
              <Label flex="0 0 80px">原紅利</Label>
              <Text size="sm">{record.value._bonus || '---'}</Text>
            </Row>
            <Row key="diff">
              <Label flex="0 0 80px">差額</Label>
              <Text size="sm">{diff}</Text>
            </Row>
            <Row key="bonus">
              <Label flex="0 0 80px">剩餘紅利</Label>
              <Text size="sm">{record.value.bonus || '---'}</Text>
            </Row>
            <Row key="note">
              <Label flex="0 0 80px">備註</Label>
              <Text size="sm">{parseNote(record)}</Text>
            </Row>
          </div>
        );
      },
    },
  ],
  credits: [
    {
      title: '日期',
      dataIndex: 'created',
      key: 'created',
      render: (created) => dateUtil.format(created),
      responsive: ['sm'],
    },
    {
      title: '原點數',
      key: '_credits',
      render: (record) => record.value._credits || '---',
      responsive: ['sm'],
    },
    {
      title: '差額',
      key: 'diff',
      render: (record) => {
        if (!record.value.diff) {
          return record.value.credits - record.value._credits;
        } else {
          return (
            `${record.value.diff || ''} ${
              record.value.feedback ? '+' + record.value.feedback : ''
            }`.trim() || '---'
          );
        }
      },
      responsive: ['sm'],
    },
    {
      title: '剩餘點數',
      key: 'credits',
      render: (record) => record.value.credits || '---',
      responsive: ['sm'],
    },
    {
      title: '備註',
      key: 'note',
      render: (record) => parseNote(record),
      responsive: ['sm'],
    },

    {
      title: '紀錄',
      key: 'id',
      className: 'rwd-mobile',
      render: (record) => {
        const diff = !record.value.diff
          ? record.value.credits - record.value._credits
          : `${record.value.diff || ''} ${
              record.value.feedback ? '+' + record.value.feedback : ''
            }`.trim() || '---';

        return (
          <div>
            <Row key="created">
              <Label flex="0 0 80px">日期</Label>
              <Text size="sm">{dateUtil.format(record.created)}</Text>
            </Row>
            <Row key="_credits">
              <Label flex="0 0 80px">原點數</Label>
              <Text size="sm">{record.value._credits || '---'}</Text>
            </Row>
            <Row key="diff">
              <Label flex="0 0 80px">差額</Label>
              <Text size="sm">{diff}</Text>
            </Row>
            <Row key="credits">
              <Label flex="0 0 80px">剩餘點數</Label>
              <Text size="sm">{record.value.credits || '---'}</Text>
            </Row>
            <Row key="note">
              <Label flex="0 0 80px">備註</Label>
              <Text size="sm">{parseNote(record)}</Text>
            </Row>
          </div>
        );
      },
    },
  ],
};

export default function CreditHistoryTable(props) {
  const app = useContext(Context);
  const {type, search} = props;
  const {profile, loading} = app.state;
  const [records, setRecords] = useState([]);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });
  const [total, setTotal] = useState(0);

  function filtersChange(obj) {
    setFilters((prev) => ({...prev, ...obj}));
  }

  const {dimension} = useDimensions();

  const [jwtToken, setJwtToken] = useState();

  const getRecords = useCallback(async () => {
    app.actions.setLoading(true);

    if (jwtToken) {
      try {
        let resp = await app.actions.getHistories(
          {
            query: {
              instance_id: profile.id,
              model: 'UserProfile',
              ...(type === UISTATE.bonus && {'value.bonus': {$exists: true}}),
              ...(type === UISTATE.credits && {
                'value.credits': {$exists: true},
              }),
            },
            paging: {
              limit: filters.limit,
              offset: (filters.current - 1) * filters.limit,
            },
            sorting: ['-created'],
          },
          jwtToken,
        );

        const {results, total} = resp;

        setRecords(results);
        setTotal(total);
      } catch (ex) {
        console.warn(ex);
      }
    }

    app.actions.setLoading(false);
  }, [profile, filters, jwtToken, type]);

  useEffect(() => {
    (async () => {
      let {token} = await app.actions.getJwtToken();
      setJwtToken(token);
    })();
  }, []);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Table
        loading={loading}
        columns={columns[type]}
        rowKey="id"
        dataSource={records}
        onChange={(pagination) => filtersChange(pagination)}
        pagination={{
          total,
          pageSize: filters.limit,
          current: filters.current,
        }}
        locale={{
          emptyText: (
            <Image
              src="/images/empty-order.svg"
              height="80"
              width="80"
              alt="empty history"
            />
          ),
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .ant-table .ant-table-cell.rwd-mobile {
    @media screen and (min-width: ${appConfig.breakpoints.sm}px) {
      display: none;
    }
  }
`;
