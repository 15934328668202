import React, {useContext, useEffect} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Layout from '../../Components/ProfileLayout';
import DepositCreditForm from '../../Forms/DepositCreditForm';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';
import CreditHistoryTable from '../../Components/CreditHistoryTable';
import LargeScreenTable from '../../Components/OrderTable/LargeScreenTable';
import SmallScreenTable from '../../Components/OrderTable/SmallScreenTable';
import useDimensions from '../../hooks/use-dimension';
import useOrderTable from '../../hooks/use-order-table';
const appConfig = require('../../data.json');
const qs = require('query-string');

export default function AccountManage(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;
  const query = qs.parse(props.location.search);
  const {loading} = app.state;
  const {dimension} = useDimensions();
  const {orders, columns, filters, total, changeFilters} = useOrderTable({
    pathname: props.location.pathname,
    query,
    defaultType: ORDER_TABLE_TYPES.CREDIT_ORDER,
  });

  if (!app.state.profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <Layout pathname={pathname} tabName={query.tabName}>
        {({tab}) => {
          switch (tab.name) {
            case 'depositCredit':
              return <DepositCreditForm />;

            case 'depositRecord':
              return (
                <div>
                  {dimension.innerWidth > appConfig.breakpoints.sm ? (
                    <LargeScreenTable
                      loading={loading}
                      orders={orders}
                      columns={columns}
                      filters={filters}
                      setFilters={changeFilters}
                      total={total}
                    />
                  ) : (
                    <SmallScreenTable
                      loading={loading}
                      orders={orders}
                      columns={columns}
                      filters={filters}
                      setFilters={changeFilters}
                      total={total}
                    />
                  )}
                </div>
              );

            case 'creditsHistory':
              return <CreditHistoryTable type="credits" />;

            case 'bonusHistory':
              return <CreditHistoryTable type="bonus" />;

            default:
              return null;
          }
        }}
      </Layout>
    </ContentWrapper>
  );
}
